import store from '@/store/index'

// 获取菜单，树结构
export async function menuList () {
  if (!store.state.WEBSITE_MENU.length) {
    await store.dispatch('getWebsiteMenuList')
  }
  return store.state.WEBSITE_MENU
}

// 获取活动列表：1:首页轮播图, 2:公司新闻, 3:行业动态, 4:logo,5:手机版轮播图
export async function banner () {
  if (!store.state.WEBSITE_BANNER.length) {
    await store.dispatch('getWebsiteBannerList')
  }
  return store.state.WEBSITE_BANNER
}

// 树扁平化
export function treeToArray (tree) {
  var res = []
  for (const item of tree) {
    const { children, ...i } = item
    if (children && children.length) {
      res = res.concat(treeToArray(children))
    }
    res.push(i)
  }
  return res
}

// 格式化菜单
export function formatMenuTree (tree, pre) {
  return tree.map(item => {
    const _children = item.children ? formatMenuTree(item.children, item) : []
    return {
      _p_key: item._p_key || pre.code || '', // 父级code
      _p_href: item._p_href || pre.href || '', // 父级href
      title: item.name,
      key: item.code,
      href: item.href,
      children: _children,
      content: item.content,
      seo_desc: item.seo_desc,
      seo_title: item.seo_title,
      seo_key_words: item.seo_key_words,
      view_count: item.view_count,
      top_pic: item.top_pic || pre.top_pic
    }
  })
}

// 获取与菜单相匹配的路由格式， 菜单链接最后没有/，但是路径可能带/，比如/home和/home/是同一个路由
export function getMenuPath (path) {
  if (path.endsWith('/')) { // 菜单链接最后没有/，但是路径可能带/，比如/home和/home/是同一个路由
    return path.slice(0, -1)
  }
  return path
}
