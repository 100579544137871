import Vue from 'vue'
import Vuex from 'vuex'
import { getNavList } from '@/api/officialWebsite'
import { getBannerList } from '@/api/admin'
import { formatMenuTree, treeToArray } from '@/utils/website'

let menuPromise = null
let bannerPromise = null

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /** 参考bootstrop划分屏幕规则
     * 手机: <768px
     * 平板：<=768px
     * 电脑：>=992px
     * 大屏：>=1200px
     */
    IS_LARGE_SCREEN: document.body.clientWidth >= 992, // 屏幕宽度是否大于等于992px
    WEBSITE_MENU: [], // 官网-菜单,树结构
    FLATTEN_MENU: [], //  官网-菜单，扁平化数据
    WEBSITE_BANNER: [], // 官网-banner图片配置,[0:首页轮播图, 1:公司新闻, 2:行业动态, 3:logo,4:手机版轮播图]
    HEADER_HEIGHT: 68 // 头部高度,默认68
  },
  mutations: {
    changeScreenSize (state, value) {
      state.IS_LARGE_SCREEN = value
    },
    setFlattenMenu (state, list) {
      console.log('store 设置扁平化菜单')
      state.FLATTEN_MENU = treeToArray(list)
    },
    getHeaderHeight (state, height) {
      state.HEADER_HEIGHT = height
      console.log('header高度：', state.HEADER_HEIGHT)
    }
  },
  actions: {
    // 获取->官网菜单
    getWebsiteMenuList ({ commit, state, dispatch }) {
      if (!menuPromise) {
        menuPromise = new Promise((resolve, reject) => {
          getNavList().then(tree => {
            console.log('2211', tree)
            // 找到并且删除 市场巡展
            for (let i = 0; i < tree.length; i++) {
              if (tree[i].children.length > 0) {
                for (let k = 0; k < tree[i].children.length; k++) {
                  if (tree[i].children[k].name == '市场巡展') {
                    // 找到了市场巡展，使用splice删除它
                    tree[i].children.splice(k, 1)
                    // 然后跳出循环
                    break
                  }
                }
              }
            }
            state.WEBSITE_MENU = formatMenuTree(tree, {})
            console.log('store 获取菜单')
            commit('setFlattenMenu', JSON.parse(JSON.stringify(state.WEBSITE_MENU)))
            resolve()
          }).finally(() => {
            menuPromise = null
          })
        })
      }
      return menuPromise
    },
    // 获取->活动列表
    getWebsiteBannerList ({ state }) {
      if (!bannerPromise) {
        bannerPromise = new Promise((resolve, reject) => {
          getBannerList({
            page: 1,
            per_page: 20
          }).then(list => {
            state.WEBSITE_BANNER = list
            resolve()
          }).finally(() => {
            bannerPromise = null
          })
        })
      }
      return bannerPromise
    }
  },
  getters: {
    IS_LARGE_SCREEN: state => state.IS_LARGE_SCREEN,
    WEBSITE_MENU: state => state.WEBSITE_MENU,
    FLATTEN_MENU: state => state.FLATTEN_MENU,
    WEBSITE_BANNER: state => state.WEBSITE_BANNER,
    HEADER_HEIGHT: state => state.HEADER_HEIGHT

  }
})
