import Vue from 'vue'
import {
  Pagination, Button, Icon, Row, Col, Form, Input, Radio, Checkbox, Select, Switch, TimePicker, Upload, Carousel,
  FormModel, Empty, Table, Popconfirm, Spin, message, Modal, Drawer, Space, List, Tree, Tag, BackTop, Menu, Progress,
  Tabs, Card, Steps
} from 'ant-design-vue'

const TabPane = Tabs.TabPane
const CardMeta = Card.Meta

Vue.component(Pagination.name, Pagination)
Vue.component(Button.name, Button)
Vue.component(Icon.name, Icon)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(Form.name, Form)
Vue.component(Form.Item.name, Form.Item)
Vue.component(Input.name, Input)
Vue.component(Input.Search.name, Input.Search)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Switch.name, Switch)
Vue.component(TimePicker.name, TimePicker)
Vue.component(Carousel.name, Carousel)
Vue.component(Upload.name, Upload)
Vue.component(Empty.name, Empty)
Vue.component(Table.name, Table)
Vue.component(Popconfirm.name, Popconfirm)
Vue.component(Spin.name, Spin)
Vue.component(Modal.name, Modal)
Vue.component(Drawer.name, Drawer)
Vue.component(Select.name, Select)
Vue.component(Select.Option.name, Select.Option)
Vue.component(Space.name, Space)
Vue.component(List.name, List)
Vue.component(List.Item.name, List.Item)
Vue.component(List.Item.Meta.name, List.Item.Meta)
Vue.component(Tree.name, Tree)
Vue.component(Tag.name, Tag)
Vue.component(BackTop.name, BackTop)
Vue.component(Radio.name, Radio)
Vue.component(Radio.Group.name, Radio.Group)
Vue.component(Menu.name, Menu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Menu.SubMenu.name, Menu.SubMenu)
Vue.component(Progress.name, Progress)
Vue.component(Tabs.name, Tabs)
Vue.use(Steps)
Vue.component(TabPane.name, TabPane)
Vue.component(Card.name, Card)
Vue.component(CardMeta.name, CardMeta)

Vue.use(FormModel)
Vue.prototype.$message = message
Vue.prototype.$modal = Modal
Vue.prototype.$form = Form
