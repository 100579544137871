// 判断设备是 pc端 还是 移动端
export const isMobile = () => {
  const userAgent = window.navigator.userAgent

  if (/iPhone/i.test(userAgent) || /Android/i.test(userAgent)) {
    // 移动端
    return true
  }
  // pc端
  return false
}

const install = Vue => {
  Vue.prototype.$isMobile = isMobile()
}

export default {
  install
}