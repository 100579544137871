import Vue from 'vue'
import VueRouter from 'vue-router'
import adminRouter from './modules/admin'
import homeRouter from './modules/home'
import { getUserInfo } from '@/utils/auth'

Vue.use(VueRouter)

// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: () => import('@/pages/layout/Layout.vue'),
    children: homeRouter
  },
  {
    path: '/admin',
    component: () => import('@/pages/layout/AdminLayout.vue'),
    children: adminRouter,
    meta: {
      admin: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/pages/admin/Login.vue')
  },
  {
    path: '*',
    name: '404',
    redirect: '/'
    // component: () => import(/* webpackChunkName: "404" */ '@/pages/404.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }) // 切换路由后让页面回到顶部
})

router.beforeEach((to, form, next) => {
  // 登录权限
  if (to.matched.some(record => record.meta.admin)) {
    if (getUserInfo()) {
      next()
    } else {
      Vue.prototype.$message.error('请先登录')
      next({
        name: 'Login'
      })
    }
  } else {
    next()
  }
})

export default router
