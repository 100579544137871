export default [
  // 首页
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '',
    component: () => import('@/pages/Home.vue'),
    name: 'Home',
    meta: { title: '广州市农药肥料应用协会', icon: 'dashboard', affix: true }
  },
  {
    path: 'recruitingFirm',
    component: () => import('@/pages/official/RecruitAgent/RecruitingFirm'),
    name: 'recruitingFirm',
    meta: { title: '会员招募', icon: 'dashboard', affix: true }
  },
  {
    path: 'information',
    component: () => import('@/pages/official/News/Index'),
    name: 'information',
    meta: { title: '协会动态', icon: 'dashboard', affix: true }
  },
  {
    path: 'newsDetail',
    component: () => import('@/pages/official/News/detail'),
    name: 'newsDetail',
    meta: { title: '动态详情', affix: true }
  },
  {
    path: 'solution',
    name: 'solution',
    // redirect: '/retailStore'
    component: () => import('@/pages/official/Solution/Index'),
    meta: { title: '' }
  },
  {
    path: 'business',
    name: 'business',
    // redirect: '/newbazaarIntroduce',
    component: () => import('@/pages/official/Business/Index'),
    meta: { title: '' }
  },
  {
    path: 'video',
    component: () => import('@/pages/official/Video/Index'),
    name: 'video',
    meta: { title: '视频播放' }
  }

  // {
  //   path: 'Index',
  //   component: () => import('@/pages/official/RecruitAgent/Index'),
  //   name: 'Index',
  //   meta: { title: '招募合作', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'recruitAgent',
  //   component: () => import('@/pages/official/RecruitAgent/RecruitAgent'),
  //   name: 'recruitAgent',
  //   meta: { title: '招募代理', icon: 'dashboard', affix: true }
  // },

  // // 关于我们
  // {
  //   path: 'about',
  //   name: 'about',
  //   component: () => import('@/pages/official/About/Index'),
  //   meta: { title: '' }
  //   // redirect: '/companyProfile'
  // },
  // {
  //   path: 'companyProfile',
  //   component: () => import('@/pages/official/About/Index'),
  //   name: 'companyProfile',
  //   meta: { title: '企业简介', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'corporateCulture',
  //   component: () => import('@/pages/official/About/Index'),
  //   name: 'corporateCulture',
  //   meta: { title: '企业文化', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'developmentHistory',
  //   component: () => import('@/pages/official/About/Index'),
  //   name: 'developmentHistory',
  //   meta: { title: '发展历程', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'enterpriseHonor',
  //   component: () => import('@/pages/official/About/Index'),
  //   name: 'enterpriseHonor',
  //   meta: { title: '获得荣誉', icon: 'dashboard', affix: true }
  // },
  // // {
  // //   path: 'recruit',
  // //   component: () => import('@/pages/official/About/Index'),
  // //   name: 'recruit',
  // //   meta: { title: '人才招聘', icon: 'dashboard', affix: true }
  // // },
  // {
  //   path: 'hotline',
  //   component: () => import('@/pages/official/About/Index'),
  //   name: 'hotline',
  //   meta: { title: '联系我们', icon: 'dashboard', affix: true }
  // },

  // // 新闻动态
  // {
  //   path: 'news',
  //   name: 'news',
  //   redirect: '/information'
  // },
  // {
  //   path: 'industryDynamics',
  //   component: () => import('@/pages/official/News/Index'),
  //   name: 'industryDynamics',
  //   meta: { title: '行业动态', icon: 'dashboard', affix: true }
  // },

  // // 新闻动态 手机端 路由
  // {
  //   path: 'mobileNews',
  //   component: () => import('@/pages/official/News/mobile'),
  //   name: 'mobileNews',
  //   meta: { title: '新闻动态' }
  // },
  // {
  //   path: 'mobileNewsDetail',
  //   component: () => import('@/pages/official/News/mobile/detail'),
  //   name: 'mobileNewsDetail',
  //   meta: { title: '新闻详情' }
  // },

  // // 企业业务

  // {
  //   path: 'characteristic',
  //   component: () => import('@/pages/official/Business/Index'),
  //   name: 'characteristic',
  //   meta: { title: '三大特色', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'instructions',
  //   component: () => import('@/pages/official/Business/Index'),
  //   name: 'instructions',
  //   meta: { title: '使用说明', icon: 'dashboard', affix: true }
  // },

  // {
  //   path: 'retailStore',
  //   component: () => import('@/pages/official/Solution/Index'),
  //   name: 'retailStore',
  //   meta: { title: '功能介绍', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'distributor',
  //   component: () => import('@/pages/official/Solution/Index'),
  //   name: 'distributor',
  //   meta: { title: '应用实例', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'manufacturer',
  //   component: () => import('@/pages/official/Solution/Index'),
  //   name: 'manufacturer',
  //   meta: { title: '使用教程', icon: 'dashboard', affix: true }
  // },
  // {
  //   path: 'video',
  //   component: () => import('@/pages/official/Video/Index'),
  //   name: 'video',
  //   meta: { title: '视频播放' }
  // },
  // {
  //   path: 'mobileVideo',
  //   component: () => import('@/pages/official/Video/mobileIndex'),
  //   name: 'mobileVideo',
  //   meta: { title: '视频播放' }
  // },
  // {
  //   path: 'video_bxt',
  //   component: () => import('@/pages/official/Video/videoBxt'),
  //   name: 'video_bxt',
  //   meta: { title: '视频播放' }
  // },
  // {
  //   path: 'mobileVideoBxt',
  //   component: () => import('@/pages/official/Video/mobileVideoBxt'),
  //   name: 'mobileVideoBxt',
  //   meta: { title: '视频播放' }
  // }
]
