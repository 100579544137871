import { requestPost, requestGet } from '@/plugins/apiOfficial'
import { api, apiGet } from '@/plugins/api'

// 获取某段富文本html
export async function getWebDetail (params) {
  return requestPost('/website_pages/detail', params)
}

// 获取导航菜单,get请求
export function getNavList (params = {}) {
  return apiGet('/website_pages/index', params)
}

// 获取岗位
export function getRecruitList (params = {}) {
  return api('/recruit/list', params)
}

// 新闻资讯/行业动态/场景案例列表(后台和网页共用)
export function getAdvisoryList (params = {}) {
  return api('/website_advisory/list', params)
}

// 新闻/行业动态/场景案例详情(后台和网页共用)
export function getAdvisoryDetail (params = {}) {
  return api('/website_advisory/detail', params)
}
// 发布内容列表(后台网页共用)
export function getPublishList (params = {}) {
  return api('/publish/list', params)
}

// 获取客服信息
export const getServiceList = async (param = {}) => {
  var res = await requestGet('/website_pages/get_service')
  return res.data
}

// 预约购买
export const makeAnAppointment = async (param = {}) => {
  var res = await requestPost('/message/add', param)
  return res.data
}

// 添加浏览量
export const addView = async (param = {}) => {
  var res = await requestPost('/website_pages/add_view', param)
  return res.data
}

// 新闻动态聚合获取, 每个分类获取前三条
export const getNewsCategory = async (param = {}) => {
  return api('/website_advisory/polymerization', param)
}

// 发布内容聚合
export const getPublishListByType = async (param = {}) => {
  return api('/publish/list_by_type', param)
}
