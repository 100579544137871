import { api } from '@/plugins/api'

// 登录
export function login (params) {
  if (!params.username) {
    return Promise.reject({
      msg: '请输入账号'
    })
  }

  if (!params.password) {
    return Promise.reject({
      msg: '请输入密码'
    })
  }

  return api('/user/login', params)
}

// 广告列表
export function getBannerList (params) {
  return new Promise((resolve, reject) => {
    api('/banner/list', params).then(res => {
      const list = []
      const config = {
        1: {
          max: 5,
          title: '首页轮播图（PC版）',
          height: '800',
          width: '1920'
        },
        2: {
          max: 1,
          title: '会员招募',
          height: '300',
          width: '1920'
        },
        3: {
          max: 1,
          title: '有奖征集',
          list: [],
          height: '300',
          width: '1920'
        },
        4: {
          max: 1,
          title: '公司logo(暂不可用)',
          height: '114',
          width: '388'
        },
        5: {
          max: 5,
          title: '首页轮播图（手机版）',
          height: '620',
          width: '375'
        }
      }
      for (const key in res) {
        const item = config[key]
        list.push({
          title: item.title,
          max: item.max,
          index: key.toString(),
          width: item.width,
          height: item.height,
          list: res[key]
        })
      }
      console.log(list)
      resolve(list)
    }).catch(reject)
  })
}

// 修改广告状态
export function changeBannerStatus (params) {
  return api('/banner/change_status', params)
}

// 添加广告
export function addBanner (params) {
  return api('/banner/add', params)
}

// 编辑广告
export function updateBanner (params) {
  return api('/banner/update', params)
}

// 获取导航列表
export function getNavList (params) {
  return api('/website_pages/list', params)
}

// 添加导航
export function addNav (params) {
  return api('/website_pages/add', params)
}

// 添加导航
export function updateNav (params) {
  return api('/website_pages/update', params)
}

// 修改导航SEO信息
export function setNavSeo (params) {
  return api('/website_pages/set_seo', params)
}

// 删除导航
export function removeNav (params) {
  return api('/website_pages/delete', params)
}

// 获取导航详情
export function getNavDetail (params) {
  return api('/website_pages/detail', params)
}

// 获取导航详情
export function updateNavDetail (params) {
  return api('/website_pages/update_content', params)
}

// 修改导航父级
export function updateNavParent (params) {
  return api('/website_pages/update', params)
}

// 修改导航图片
export function updateNavBanner (params) {
  return api('/website_pages/update_top_pic', params)
}

// 获取职位列表
export function getRecruitList (params) {
  return api('/recruit/list', params)
}

// 修改职位内容
export function updateRecruit (params) {
  return api('/recruit/update', params)
}

// 添加职位
export function addRecruit (params) {
  return api('/recruit/add', params)
}

// 删除职位内容
export function removeRecruit (params) {
  return api('/recruit/del', params)
}

// 获取内容列表
export function publishList (params) {
  return api('/publish/list/tevv', params)
}

// 修改发布内容
export function updatePublish (params) {
  return api('/publish/update', params)
}

// 添加发布内容
export function addPublish (params) {
  return api('/publish/add', params)
}

// 删除发布内容
export function removePublish (params) {
  return api('/publish/del', params)
}

// 获取文章列表
export function getArticleList (params) {
  return api('/website_advisory/list/tevv', params)
}

// 添加文章
export function addArticle (params) {
  return api('/website_advisory/add', params)
}

// 获取文章详情
export function getArticleDetail (params) {
  return api('/website_advisory/detail', params)
}

// 编辑文章
export function updateArticle (params) {
  return api('/website_advisory/update', params)
}

// 修改文章状态
export function updateArticleStatus (params) {
  return api('/website_advisory/update_status', params)
}

// 获取新闻动态类型
export function getNewsCategory (params) {
  return api('/manage/website_classification/list', params)
}

// 获取预约顾问列表
export function getConsultantList (params) {
  return api('/message/list', params)
}
