import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import './plugins/ant-design-vue'
import { api } from './plugins/api'
import tools from './plugins/tools'
import './assets/style/reset.css'
import components from '@/components'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Viewer.setDefaults({
  Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' }
})

// 注册全局组件
Object.keys(components).forEach(key => Vue.component(key, components[key]))

// 注册全局工具方法
Vue.use(tools)

Vue.use(VueAwesomeSwiper/* { default options with global component } */)
Vue.use(Meta)

Vue.config.productionTip = false

// Vue.config.debug = true
// Vue.config.devtools = true

Vue.prototype.$api = api

new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('my-app-element'))
  },
  render: h => h(App)
}).$mount('#app')
