export default [
  {
    path: '',
    name: 'Admin',
    redirect: 'ad',
    component: () => import(/* webpackChunkName: "Admin" */ '@/pages/admin/Index.vue')
  },
  {
    path: 'ad', // 广告管理
    name: 'Ad',
    component: () => import(/* webpackChunkName: "Ad" */ '@/pages/admin/Ad.vue')
  },
  {
    path: 'menu', // 页面管理
    name: 'Menu',
    component: () => import(/* webpackChunkName: "Menu" */ '@/pages/admin/menu/Menu.vue')
  },
  {
    path: 'menu/content', // 页面管理
    name: 'MenuContent',
    component: () => import(/* webpackChunkName: "MenuContent" */ '@/pages/admin/menu/MenuContent.vue')
  },
  {
    path: 'articles', // 新闻资讯
    name: 'Articles',
    component: () => import(/* webpackChunkName: "Articles" */ '@/pages/admin/articles/Articles.vue')
  },
  {
    path: '/articles/detail', // 新闻资讯详情
    name: 'ArticlesDetail',
    component: () => import(/* webpackChunkName: "ArticlesDetail" */ '@/pages/admin/articles/Detail.vue')
  },
  {
    path: 'content', // 内容管理
    name: 'Content',
    component: () => import(/* webpackChunkName: "content" */ '@/pages/admin/content/Content.vue')
  },
  {
    path: 'recruit', // 招聘管理
    name: 'Recruit',
    component: () => import(/* webpackChunkName: "Recruit" */ '@/pages/admin/Recruit.vue')
  },
  {
    path: 'consultant', // 招聘管理
    name: 'Consultant',
    component: () => import(/* webpackChunkName: "Recruit" */ '@/pages/admin/Consultant/Consultant.vue')
  }
]
