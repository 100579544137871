
export function getUserInfo () {
  return sessionStorage.getItem('userinfo')
}

export function setUserInfo (info) {
  sessionStorage.setItem('userinfo', info)
}

export function removeUserInfo () {
  sessionStorage.removeItem('userinfo')
}
