<template>
  <div class="more gn-full-x gn-flex-center">
    <div v-if="status === 'loadmore'" class="text" @click="load">
      加载更多
      <a-icon type="down" :style="{ color: '#00A0AC', fontSize: '16px' }" />
    </div> 
    
    <a-icon v-if="status === 'loading'" type="loading" class="loading" />
    <div v-if="status === 'nomore'" class="text">没有更多了</div>
    <div v-if="status === 'empty'">
      <a-empty style="color: #888; font-size: 16px" :image="simpleImage" description='暂无数据' />
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';

export default {
  props: {
    status: {
      type: String,
      required: true,
      validator: function(value) {
        return ['loadmore', 'loading', 'nomore', 'empty'].indexOf(value) !== -1
      },
      default: () => 'loadmore'
    }
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  methods: {
    load() {
      this.$emit('load')
    }
  }
}
</script>

<style lang='scss' scoped>
  .more{
    .text{
      cursor: pointer;
      font-size: 24px;
      color: #333333;
      margin-right: 5px;
    }
    .loading{
      color: #00A0AC;
      font-size: 25px;
    }
  }
</style>