import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_OFFICIAL_API,
  withCredentials: true,
  timeout: 5000
})

instance.interceptors.request.use(function (config) {
  console.log('请求')
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  const res = response.data
  if (res.code !== '0') {
    if (res.code === '-1') {
      return Promise.reject(res.msg || 'Error')
    }
    return res
  } else {
    return res
  }
}, function (error) {
  return Promise.reject(error)
})

export function requestPost (url, params) {
  return instance.post(url, params)
}

export function requestGet (url) {
  return instance.get(url)
}
