<template>
  <div class="app" id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
// @import url('./assets/style/reset.css');
.app {
  height: 100%;
}
</style>
